import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import HeaderPadding from '../layout/HeaderPadding';
import { usePageTitle } from '../hooks/usePageTitle';
import { BlockContent } from '../components/block-content';
import { LanguageContext } from '../layout/Layout';
import { useContext } from 'react';
import SEO from '../components/seo';

export const query = graphql`
  query JobTemplateQuery($id: String!) {
    case: sanityJob(id: {eq: $id}) {
        title {
            nb
            en
          }
          _rawBody
    }
  }
`

const Job = ({ data: { case: project } }) => {

  const lang = useContext(LanguageContext);
  const title = project.title && project.title[lang] || project.title && project.title['nb'];
  const body = project._rawBody && project._rawBody[lang] || project._rawBody && project._rawBody['nb'] || [];

  usePageTitle(title);
  return (
    <Fragment>
      <SEO title={title} />
      <HeaderPadding />
      <BlockContent blocks={body} />
    </Fragment>
  )
}

export default Job
